const currencies = [
	{value: "afn", text: "Afghan Afghani", symbol: "؋"},
	{value: "all", text: "Albanian Lek", symbol: "Lek"},
	{value: "dzd", text: "Algerian Dinar", symbol: "دج"},
	{value: "aoa", text: "Angolan Kwanza", symbol: "Kz"},
	{value: "ars", text: "Argentine Peso", symbol: "$"},
	{value: "amd", text: "Armenian Dram", symbol: "֏"},
	{value: "awg", text: "Aruban Florin", symbol: "ƒ"},
	{value: "aud", text: "Australian Dollar", symbol: "$"},
	{value: "azn", text: "Azerbaijani Manat", symbol: "m"},
	{value: "bsd", text: "Bahamian Dollar", symbol: "B$"},
	{value: "bhd", text: "Bahraini Dinar", symbol: ".د.ب"},
	{value: "bdt", text: "Bangladeshi Taka", symbol: "৳"},
	{value: "bbd", text: "Barbadian Dollar", symbol: "Bds$"},
	{value: "byr", text: "Belarusian Ruble", symbol: "Br"},
	{value: "bef", text: "Belgian Franc", symbol: "fr"},
	{value: "bzd", text: "Belize Dollar", symbol: "$"},
	{value: "bmd", text: "Bermudan Dollar", symbol: "$"},
	{value: "btn", text: "Bhutanese Ngultrum", symbol: "Nu."},
	{value: "btc", text: "Bitcoin", symbol: "฿"},
	{value: "bob", text: "Bolivian Boliviano", symbol: "Bs."},
	{value: "bam", text: "Bosnia-Herzegovina Convertible Mark", symbol: "KM"},
	{value: "bwp", text: "Botswanan Pula", symbol: "P"},
	{value: "brl", text: "Brazilian Real", symbol: "R$"},
	{value: "gbp", text: "British Pound Sterling", symbol: "£"},
	{value: "bnd", text: "Brunei Dollar", symbol: "B$"},
	{value: "bgn", text: "Bulgarian Lev", symbol: "Лв."},
	{value: "bif", text: "Burundian Franc", symbol: "FBu"},
	{value: "khr", text: "Cambodian Riel", symbol: "KHR"},
	{value: "cad", text: "Canadian Dollar", symbol: "$"},
	{value: "cve", text: "Cape Verdean Escudo", symbol: "$"},
	{value: "kyd", text: "Cayman Islands Dollar", symbol: "$"},
	{value: "xof", text: "CFA Franc BCEAO", symbol: "CFA"},
	{value: "xaf", text: "CFA Franc BEAC", symbol: "FCFA"},
	{value: "xpf", text: "CFP Franc", symbol: "₣"},
	{value: "clp", text: "Chilean Peso", symbol: "$"},
	{value: "cny", text: "Chinese Yuan", symbol: "¥"},
	{value: "cop", text: "Colombian Peso", symbol: "$"},
	{value: "kmf", text: "Comorian Franc", symbol: "CF"},
	{value: "cdf", text: "Congolese Franc", symbol: "FC"},
	{value: "crc", text: "Costa Rican ColÃ³n", symbol: "₡"},
	{value: "hrk", text: "Croatian Kuna", symbol: "kn"},
	{value: "cuc", text: "Cuban Convertible Peso", symbol: "$, CUC"},
	{value: "czk", text: "Czech Republic Koruna", symbol: "Kč"},
	{value: "dkk", text: "Danish Krone", symbol: "Kr."},
	{value: "djf", text: "Djiboutian Franc", symbol: "Fdj"},
	{value: "dop", text: "Dominican Peso", symbol: "$"},
	{value: "xcd", text: "East Caribbean Dollar", symbol: "$"},
	{value: "egp", text: "Egyptian Pound", symbol: "ج.م"},
	{value: "ern", text: "Eritrean Nakfa", symbol: "Nfk"},
	{value: "eek", text: "Estonian Kroon", symbol: "kr"},
	{value: "etb", text: "Ethiopian Birr", symbol: "Nkf"},
	{value: "eur", text: "Euro", symbol: "€"},
	{value: "fkp", text: "Falkland Islands Pound", symbol: "£"},
	{value: "fjd", text: "Fijian Dollar", symbol: "FJ$"},
	{value: "gmd", text: "Gambian Dalasi", symbol: "D"},
	{value: "gel", text: "Georgian Lari", symbol: "ლ"},
	{value: "dem", text: "German Mark", symbol: "DM"},
	{value: "ghs", text: "Ghanaian Cedi", symbol: "GH₵"},
	{value: "gip", text: "Gibraltar Pound", symbol: "£"},
	{value: "grd", text: "Greek Drachma", symbol: "₯, Δρχ, Δρ"},
	{value: "gtq", text: "Guatemalan Quetzal", symbol: "Q"},
	{value: "gnf", text: "Guinean Franc", symbol: "FG"},
	{value: "gyd", text: "Guyanaese Dollar", symbol: "$"},
	{value: "htg", text: "Haitian Gourde", symbol: "G"},
	{value: "hnl", text: "Honduran Lempira", symbol: "L"},
	{value: "hkd", text: "Hong Kong Dollar", symbol: "$"},
	{value: "huf", text: "Hungarian Forint", symbol: "Ft"},
	{value: "isk", text: "Icelandic KrÃ³na", symbol: "kr"},
	{value: "inr", text: "Indian Rupee", symbol: "₹"},
	{value: "idr", text: "Indonesian Rupiah", symbol: "Rp"},
	{value: "irr", text: "Iranian Rial", symbol: "﷼"},
	{value: "iqd", text: "Iraqi Dinar", symbol: "د.ع"},
	{value: "ils", text: "Israeli New Sheqel", symbol: "₪"},
	{value: "itl", text: "Italian Lira", symbol: "L,£"},
	{value: "jmd", text: "Jamaican Dollar", symbol: "J$"},
	{value: "jpy", text: "Japanese Yen", symbol: "¥"},
	{value: "jod", text: "Jordanian Dinar", symbol: "ا.د"},
	{value: "kzt", text: "Kazakhstani Tenge", symbol: "лв"},
	{value: "kes", text: "Kenyan Shilling", symbol: "KSh"},
	{value: "kwd", text: "Kuwaiti Dinar", symbol: "ك.د"},
	{value: "kgs", text: "Kyrgystani Som", symbol: "лв"},
	{value: "lak", text: "Laotian Kip", symbol: "₭"},
	{value: "lvl", text: "Latvian Lats", symbol: "Ls"},
	{value: "lbp", text: "Lebanese Pound", symbol: "£"},
	{value: "lsl", text: "Lesotho Loti", symbol: "L"},
	{value: "lrd", text: "Liberian Dollar", symbol: "$"},
	{value: "lyd", text: "Libyan Dinar", symbol: "د.ل"},
	{value: "ltl", text: "Lithuanian Litas", symbol: "Lt"},
	{value: "mop", text: "Macanese Pataca", symbol: "$"},
	{value: "mkd", text: "Macedonian Denar", symbol: "ден"},
	{value: "mga", text: "Malagasy Ariary", symbol: "Ar"},
	{value: "mwk", text: "Malawian Kwacha", symbol: "MK"},
	{value: "myr", text: "Malaysian Ringgit", symbol: "RM"},
	{value: "mvr", text: "Maldivian Rufiyaa", symbol: "Rf"},
	{value: "mro", text: "Mauritanian Ouguiya", symbol: "MRU"},
	{value: "mur", text: "Mauritian Rupee", symbol: "₨"},
	{value: "mxn", text: "Mexican Peso", symbol: "$"},
	{value: "mdl", text: "Moldovan Leu", symbol: "L"},
	{value: "mnt", text: "Mongolian Tugrik", symbol: "₮"},
	{value: "mad", text: "Moroccan Dirham", symbol: "MAD"},
	{value: "mzm", text: "Mozambican Metical", symbol: "MT"},
	{value: "mmk", text: "Myanmar Kyat", symbol: "K"},
	{value: "nad", text: "Namibian Dollar", symbol: "$"},
	{value: "npr", text: "Nepalese Rupee", symbol: "₨"},
	{value: "ang", text: "Netherlands Antillean Guilder", symbol: "ƒ"},
	{value: "twd", text: "New Taiwan Dollar", symbol: "$"},
	{value: "nzd", text: "New Zealand Dollar", symbol: "$"},
	{value: "nio", text: "Nicaraguan CÃ³rdoba", symbol: "C$"},
	{value: "ngn", text: "Nigerian Naira", symbol: "₦"},
	{value: "kpw", text: "North Korean Won", symbol: "₩"},
	{value: "nok", text: "Norwegian Krone", symbol: "kr"},
	{value: "omr", text: "Omani Rial", symbol: ".ع.ر"},
	{value: "pkr", text: "Pakistani Rupee", symbol: "₨"},
	{value: "pab", text: "Panamanian Balboa", symbol: "B/."},
	{value: "pgk", text: "Papua New Guinean Kina", symbol: "K"},
	{value: "pyg", text: "Paraguayan Guarani", symbol: "₲"},
	{value: "pen", text: "Peruvian Nuevo Sol", symbol: "S/."},
	{value: "php", text: "Philippine Peso", symbol: "₱"},
	{value: "pln", text: "Polish Zloty", symbol: "zł"},
	{value: "qar", text: "Qatari Rial", symbol: "ق.ر"},
	{value: "ron", text: "Romanian Leu", symbol: "lei"},
	{value: "rub", text: "Russian Ruble", symbol: "₽"},
	{value: "rwf", text: "Rwandan Franc", symbol: "FRw"},
	{value: "svc", text: "Salvadoran ColÃ³n", symbol: "₡"},
	{value: "wst", text: "Samoan Tala", symbol: "SAT"},
	{value: "sar", text: "Saudi Riyal", symbol: "﷼"},
	{value: "rsd", text: "Serbian Dinar", symbol: "din"},
	{value: "scr", text: "Seychellois Rupee", symbol: "SRe"},
	{value: "sll", text: "Sierra Leonean Leone", symbol: "Le"},
	{value: "sgd", text: "Singapore Dollar", symbol: "$"},
	{value: "skk", text: "Slovak Koruna", symbol: "Sk"},
	{value: "sbd", text: "Solomon Islands Dollar", symbol: "Si$"},
	{value: "sos", text: "Somali Shilling", symbol: "Sh.so."},
	{value: "zar", text: "South African Rand", symbol: "R"},
	{value: "krw", text: "South Korean Won", symbol: "₩"},
	{value: "xdr", text: "Special Drawing Rights", symbol: "SDR"},
	{value: "lkr", text: "Sri Lankan Rupee", symbol: "Rs"},
	{value: "shp", text: "St. Helena Pound", symbol: "£"},
	{value: "sdg", text: "Sudanese Pound", symbol: ".س.ج"},
	{value: "srd", text: "Surinamese Dollar", symbol: "$"},
	{value: "szl", text: "Swazi Lilangeni", symbol: "E"},
	{value: "sek", text: "Swedish Krona", symbol: "kr"},
	{value: "chf", text: "Swiss Franc", symbol: "CHf"},
	{value: "syp", text: "Syrian Pound", symbol: "LS"},
	{value: "std", text: "São Tomé and Príncipe Dobra", symbol: "Db"},
	{value: "tjs", text: "Tajikistani Somoni", symbol: "SM"},
	{value: "tzs", text: "Tanzanian Shilling", symbol: "TSh"},
	{value: "thb", text: "Thai Baht", symbol: "฿"},
	{value: "top", text: "Tongan pa'anga", symbol: "$"},
	{value: "ttd", text: "Trinidad & Tobago Dollar", symbol: "$"},
	{value: "tnd", text: "Tunisian Dinar", symbol: "ت.د"},
	{value: "try", text: "Turkish Lira", symbol: "₺"},
	{value: "tmt", text: "Turkmenistani Manat", symbol: "T"},
	{value: "ugx", text: "Ugandan Shilling", symbol: "USh"},
	{value: "uah", text: "Ukrainian Hryvnia", symbol: "₴"},
	{value: "aed", text: "United Arab Emirates Dirham", symbol: "إ.د"},
	{value: "uyu", text: "Uruguayan Peso", symbol: "$"},
	{value: "usd", text: "US Dollar", symbol: "$"},
	{value: "uzs", text: "Uzbekistan Som", symbol: "лв"},
	{value: "vuv", text: "Vanuatu Vatu", symbol: "VT"},
	{value: "vef", text: "Venezuelan BolÃ­var", symbol: "Bs"},
	{value: "vnd", text: "Vietnamese Dong", symbol: "₫"},
	{value: "yer", text: "Yemeni Rial", symbol: "﷼"},
	{value: "zmk", text: "Zambian Kwacha", symbol: "ZK"}
]

export const getCurrencyPrefix = (val) => {
	const currency = currencies.find(f => f.value === val)
	return currency && currency.symbol || '£'
}

export default currencies